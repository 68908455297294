import Helmet from "react-helmet"
import React from "react"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"

export default function ThankYou() {
  return(
    <>
    <Helmet>
     <style type="text/css">{`
    @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
     * {
       font-family: "Manrope", system-ui !important;
     }
     body {
	font-family: "Manrope", system-ui !important;
	background: #fff;
	width: 100%;
	min-height: 100vh;
}

section#typage {
position:relative;
width:100%;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
height:100vh;
}

.topLogo {
position:absolute;
top:20px;
left:20px;
width:200px;
}

#typage h1 {
color:#333;
font-size:32px !important;
font-weight:800;
margin:0 0 8px 0;
padding:0;
}

#typage h2 {
color:#333;
font-size:20px !important;
font-weight:400;
margin:0 0 24px 0;
padding:0;
}


.features {
position:relative;
display:flex;
justify-content:space-between;
align-items:flex-start;
width:1024px;
margin:0 0 20px 0;
padding:24px;
background:#cad5fc;
border-radius:24px;
}

.features ul {
  height: 100%;
width: 38%;
  font-size: 17px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  list-style:none;
  padding:0;
}

.features ul li {
text-align:left;
color:#333;
margin:0 0 8px 0;
padding:24px 12px 24px 72px;
border-radius:12px;
background: #fff url('/img/manualIcon-orange.png') no-repeat 16px center / 44px;
}

.features ul li:first-child {
background: #fff url('/img/magnifyIcon-orange.png') no-repeat 16px center / 44px;
}

.features ul li:last-child {
background: #fff url('/img/browserIcon-orange.png') no-repeat 16px center / 44px;
}

.features ul li span {
font-weight:800;
}

.features video {
width:60%;
border-radius:12px;
}

#typage button {
display:block;
text-align:center;
font-size:20px;
font-weight:800;
color:#fff;
 background: linear-gradient(270deg, #fd6821, #fda321);
padding:24px 32px;
border-radius:24px;
border:1px solid #fda321;
box-shadow: 0px 0px 5px 0px rgba(253,163,33,0.25);
 transition: .2s ease;
  animation: buttonGradient 5s ease infinite;
  }
  @keyframes buttonGradient {
    0% {
      background-position: 0% 50%
    }
    50% {
      background-position: 100% 50%
    }
    100% {
      background-position: 0% 50%
    }
  }
#typage button:hover {
box-shadow: 0px 0px 20px 0px rgba(253,163,33,1);
     }
@media only screen and (max-width: 1366px) {
#typage h1 {
font-size: 28px !important;
}
#typage h2 {
font-size:16px !important;
}
.features {
      width:900px;
}
.features ul {
font-size:15px;
	}
.features ul li {
padding: 20px 12px 20px 72px;
	}

    `}
    </style>

    <title>Thank you for downloading</title>
    </Helmet>
    <section id="typage">
	<img src="/img/logo.png" alt="Manuals Logo" class="topLogo" />
	<h1>Thank You For Installing Manuals Library!</h1>
	<h2>Welcome to Manuals Library. Search thousands of product manuals with instructions and images!</h2>
	<div class="features">
		<ul>
			<li><span>Search the web & for manuals</span> by clicking the Manuals Search icon</li>
			<li><span>Easily find</span> userful manuals for any product</li>
			<li>Get <span>Yahoo powered</span> search results from the address bar</li>
		</ul>
    <video width="100%" autoPlay={"autoplay"} muted loop={"loop"}>
      <source src="/img/ManualsInstructions.mp4" type="video/mp4" />
    </video>
	</div>
	<button onClick = {() => window.triggerInstall()}>START SEARCHING FOR MANUALS</button>
	</section>
    <RawFooter></RawFooter>
    </>
  )
}
